import React from 'react'
import GatsbyLink from '../src/components/GatsbyLink'
import './Logo.scss'
import siteLogo from '../static/logos/longland-logo.svg'; 
import siteLogoReversed from '../static/logos/longland-logo-reversed.svg'; 
export const Logo = props => {
  const logoSrc = props.isReversed ? siteLogoReversed : siteLogo;
  return (
    <GatsbyLink to="/" className="logo">
      <img src={logoSrc ? logoSrc : ''} alt="Longland logo"/>
    </GatsbyLink>
  )
}
