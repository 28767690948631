import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './PostListNavigation.scss'

export const PostListNavigation = (props) => {
    let { pathPrefix, categoryFilter } = props;
    const { nodes: posts } = categoryFilter
    let categoriesArray = []
    posts && posts.map(post =>
      post.categories && post.categories.map( category => {
        let thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id)
        !thisCompare && category.slug !== 'uncategorized' && categoriesArray.push(category)
      })
    )
    // Sort the categories into a specific order
    const sortOrder = {'Annual Reports': 1, 'Half Yearly Reports' : 2,'Quarterly Reports' : 3, 'Media Releases': 4, 'Presentations' : 5, default: 1000}
    categoriesArray.sort(function (a, b) {
      return (sortOrder[a.name] || sortOrder.default) - (sortOrder[b.name] || sortOrder.default)
    });

    return (
      <div className="post-list-navigation">
        <div className="inner">
          <strong>Filter by: </strong>
          <ul>
            <li><GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">All</GatsbyLink></li>
            {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorized' && (
              <li key={index}>
                <GatsbyLink to={`/${pathPrefix}/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
}
