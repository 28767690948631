import React from 'react'
import GatsbyLink from '../GatsbyLink'
import './Breadcrumbs.scss'

export const Breadcrumbs = props => {
  const { location } = props

  if (!location) {
    return null
  }

  const { pathname } = location
  const origin = '/'
  const pathArray = pathname.split('/')
  let urlBuild = [origin]
  return (
    <div className="breadcrumbs">
      <div className="inner">
        <ul>
          <li>
            <GatsbyLink to={origin}>Home</GatsbyLink>
          </li>
          {pathArray &&
            pathArray.map((item, index) => {
              if (item) {
                urlBuild.push(item)
                return (
                  <li key={index}>
                    <span>/</span>
                    {item === 'category' ? (
                      <span>{item.replace('-', ' ')}</span>
                    ) : (
                      <GatsbyLink to={`${urlBuild.join('/')}/`} decode={true}>
                        {item.replace('-', ' ')}
                      </GatsbyLink>
                    )}
                  </li>
                )
              }
            })}
        </ul>
      </div>
    </div>
  )
}
