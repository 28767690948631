import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ListItem extends React.Component {
  render () {
    let { data, pathPrefix, showAuthor } = this.props;
    const { excerpt, title, slug, featured_image_url, author, date } = data
    const categorySlug = data.categories[0].slug;
    if (pathPrefix.includes('reports')) {
      pathPrefix += `/${categorySlug}`;
    }
    return (
      <div className="post-list-item">
        <div>
          <div className="image"><GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title}><Image src={featured_image_url} className="background" /></GatsbyLink></div>
          <div className="inner">
            <GatsbyLink to={`/${pathPrefix}/${slug}/`}><h4 className="title">{decodeEntities(title)}</h4></GatsbyLink>
            {excerpt && <RenderContent content={`${excerpt}`}/>}
            {slug && <GatsbyLink to={`/${pathPrefix}/${slug}/`} label={title} className="post-link"><FaChevronRight/></GatsbyLink>}
          </div>
          <div className="meta">
            {showAuthor && <div className="avatar"><Image src={author.avatar_urls.wordpress_48}/></div>}
            <div>
              {author && showAuthor && <GatsbyLink to={`/author/${author.slug}/`} className="author">{author.name}</GatsbyLink>}
              {date && <span className="date">{date}</span>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
