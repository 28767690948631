import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';
import siteLogoReversed from '../static/logos/longland-logo-reversed.svg'; 
import './LogoFooter.scss';

export const Logo = (props) => {
  const logoSrc = siteLogoReversed;
  return (
    <GatsbyLink to="/" className="logo-footer" aria-label="Logo">
      <img src={logoSrc ? logoSrc : ''} alt="Longland footerlogo"/>
    </GatsbyLink>
  );
}
